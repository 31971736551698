import { Stack, Typography } from "@mui/material";
import { InitialExercise } from "../../../types";

export function StatusCheck(props: { data: InitialExercise }) {
  const numBlocks = props.data.blocks.length;
  const numSpaces = props.data.spaces
    .flatMap((x) => x)
    .filter((x) => x !== "comment" && x !== "return").length;

  const blocksOk = numBlocks === numSpaces;

  const numParameters = props.data.parameters.length;
  const minParametersTests = Math.min(
    ...props.data.tests.map((x) => x.parameters.length)
  );
  const maxParametersTests = Math.max(
    ...props.data.tests.map((x) => x.parameters.length)
  );

  const paramsOk =
    numParameters === minParametersTests &&
    numParameters === maxParametersTests;

  return (
    <Stack spacing={1} sx={{ p: 1, border: "1px solid gray", borderRadius: 1 }}>
      <Typography color={blocksOk ? "success" : "error"}>
        {numBlocks}/{numSpaces} blocks/spaces
      </Typography>

      <Typography color={paramsOk ? "success" : "error"}>
        Parameters in tests: {minParametersTests} - {maxParametersTests} (should
        be {numParameters})
      </Typography>
    </Stack>
  );
}
