import { useEffect, useState } from "react";
import { ADDR } from "../../CONFIG";
import { ExerciseIndex, InitialExercise } from "../../types";

export function useByte() {
  const [byte, setByte] = useState(null as null | ExerciseIndex[]);

  useEffect(() => {
    const fetchByte = async () => {
      const response = await fetch(`${ADDR}/byte.json`);

      const data: ExerciseIndex[] = await response.json();

      setByte(data);
    };

    fetchByte();
  }, []);

  return byte;
}

export function useBit(id: number) {
  const [bit, setBit] = useState(null as null | InitialExercise);

  useEffect(() => {
    const fetchByte = async () => {
      const response = await fetch(`${ADDR}/bit/${id}.json`);

      const data: InitialExercise = await response.json();

      setBit(data);
    };

    fetchByte();
  }, []);

  return bit;
}
