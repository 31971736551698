import { Exercise } from "../../../types";

export function parse(data: Exercise) {
  const rows = data.spaces.map((row) =>
    row
      .map((space) => {
        if (space === "comment") {
          return "";
        } else if (space === "return") {
          return "return";
        } else {
          return data.blocks.find((x) => x.location === space)?.block;
        }
      })
      .filter((x) => !!x)
  );

  const nonempty_rows = rows.filter((x) => x.length > 0);

  const joined_rows = nonempty_rows.map((row) => row.join(" "));

  let alreadyAssigned = [...data.parameters];

  const js_rows = joined_rows.map((s) => {
    const isAssignment = s.match("\\s=\\s") !== null;

    const isNewAssignment = !alreadyAssigned
      .map((param) => s.match(`${param}\\s=\\s`) !== null)
      .reduce((acc, n) => acc || n, false);

    if (isAssignment && isNewAssignment) {
      alreadyAssigned.push(s.split(" ")[0]);
      return `let ${s};`;
    } else {
      return s + ";";
    }
  });

  const fn_body = js_rows.join("\n");

  const fn_constructor_args = [...data.parameters, fn_body];

  try {
    // @ts-ignore
    const fn: (...args: number[]) => number = new Function(
      ...fn_constructor_args
    );
    return fn;
  } catch (error) {
    return () => {
      throw new Error("Compilation failed.");
    };
  }
}
