import { createBrowserRouter, Navigate } from "react-router-dom";
import {
  Bit,
  bitLoader,
  Byte,
  byteLoader,
  ExerciseEditor,
  HomeRedirect,
} from "./routes";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeRedirect />,

    //@ts-ignore
    loader: byteLoader,
  },
  {
    path: "/bit/:id",
    element: <Bit />,
    errorElement: <Navigate to="/byte" />,

    // @ts-ignore
    loader: bitLoader,
  },
  {
    path: "/byte",
    element: <Byte />,

    //@ts-ignore
    loader: byteLoader,
  },
  { path: "/exercise-editor", element: <ExerciseEditor /> },
]);
