import { Navigate, useLoaderData } from "react-router-dom";
import { ExerciseIndex } from "../types";

export function HomeRedirect() {
  // We use byteLoader.
  const data = useLoaderData() as ExerciseIndex[];

  let unsorted = [...data];
  unsorted.sort((a, b) => a.id - b.id);

  const unsolved = data
    .map((x) => ({
      ...x,
      solved: localStorage.getItem(`bit/${x.id}--solved`) === "true",
    }))
    .filter((x) => !x.solved);

  if (unsolved.length === 0) {
    return <Navigate to="/bit/0" />;
  } else {
    const firstUnsolved = unsolved[0];

    return <Navigate to={`bit/${firstUnsolved.id}`} />;
  }
}
