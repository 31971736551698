import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useImmer } from "use-immer";
import { useBit, useByte } from "../hooks";
import { Column } from "./Column";

function SortTableRow(props: {
  id: number;
  label: string;
  setNumSpaces: (num: number) => void;
  suggested: number;
}) {
  const bit = useBit(props.id);

  const numSpaces = bit?.spaces
    .flatMap((x) => x)
    .filter((x) => x !== "comment" && x !== "return").length;

  useEffect(() => {
    if (bit && numSpaces !== undefined) {
      props.setNumSpaces(numSpaces);
    }
  }, [bit]);

  if (!bit) {
    return (
      <TableRow>
        <TableCell>
          <CircularProgress />
        </TableCell>
      </TableRow>
    );
  }

  const numParameters = bit.parameters.length;

  const numRows = bit.spaces.length;

  const longestRow = Math.max(
    ...bit.spaces.map(
      (x) => x.filter((x) => x !== "comment" && x !== "return").length
    )
  );

  return (
    <TableRow>
      <TableCell>{bit.id}</TableCell>
      <TableCell>{props.suggested}</TableCell>
      <TableCell>
        {numSpaces}/{longestRow}
      </TableCell>
      <TableCell>{props.label}</TableCell>
      <TableCell>{numParameters}</TableCell>
      <TableCell>{numSpaces}</TableCell>
      <TableCell>{numRows}</TableCell>
      <TableCell>{longestRow}</TableCell>
    </TableRow>
  );
}

function SortButton() {
  const [isOpen, setIsOpen] = useState(false);

  const [numSpaces, setNumSpaces] = useImmer({} as Record<number, number>);

  const byte = useByte();

  useEffect(() => {
    if (byte) {
      setNumSpaces((d) => {
        byte.forEach((x) => {
          d[x.id] = 0;
        });
      });
    }
  }, [byte]);

  if (!byte) {
    return <CircularProgress />;
  }

  const rows = [...byte];
  rows.sort((a, b) => numSpaces[a.id] - numSpaces[b.id]);

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => setIsOpen((x) => !x)}
      >
        Suggest exercise order
      </Button>

      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Suggested exercise order</DialogTitle>

        <DialogContent>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Suggested</TableCell>
                  <TableCell>Complexity</TableCell>
                  <TableCell>Label</TableCell>
                  <TableCell>Parameters</TableCell>
                  <TableCell>Spaces</TableCell>
                  <TableCell>Lines</TableCell>
                  <TableCell>Max Line Length</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {rows.map((x, i) => (
                  <SortTableRow
                    key={x.id}
                    id={x.id}
                    suggested={i}
                    label={x.label}
                    setNumSpaces={(num: number) => {
                      setNumSpaces((d) => {
                        d[x.id] = num;
                      });
                    }}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </>
  );
}

export function Importer(props: { onImport: (val: string) => void }) {
  const [state, setState] = useState("");

  return (
    <Column>
      <Typography variant="h6" component="h2">
        Import
      </Typography>

      <TextField
        multiline
        rows={32}
        InputLabelProps={{ shrink: true }}
        value={state}
        onChange={(e) => setState(e.target.value)}
      />

      <Button variant="contained" onClick={() => props.onImport(state)}>
        Import
      </Button>
      <SortButton />
    </Column>
  );
}
