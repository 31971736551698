import { InitialExercise } from "../../types";

export function parseSpaces(val: string): InitialExercise["spaces"] {
  const rows = val.split("\n");

  let id = 0;

  const parsed = rows.map((srow) => {
    if (srow.startsWith("return")) {
      const num = parseInt(srow.replace("return", ""));

      const rt = [
        "return",
        ...new Array(num).fill(null).map((x, i) => `${id + i}`),
      ];

      id += num;

      return rt;
    } else if (srow.startsWith("comment")) {
      const num = parseInt(srow.replace("comment", ""));

      const rt = [
        "comment",
        ...new Array(num).fill(null).map((x, i) => `${id + i}`),
      ];

      id += num;

      return rt;
    } else {
      const num = parseInt(srow);

      const rt = [...new Array(num).fill(null).map((x, i) => `${id + i}`)];

      id += num;

      return rt;
    }
  });

  return parsed;
}

export function parseBlocks(val: string): InitialExercise["blocks"] {
  const rows = val.split("\n");

  let id = 0;

  const parsed = rows.map((x) => {
    const rid = `b${id}`;
    id++;

    return { id: rid, block: x };
  });

  return parsed;
}

export function parseTests(val: string): InitialExercise["tests"] {
  const rows = val.split("\n");

  const parsed = rows.map((srow) => {
    const tokens = srow.split("=>").map((x) => x.trim());

    const params = tokens[0];
    const result = JSON.parse(tokens[1]);

    const ptokens = params.split(",").map((x) => x.trim());

    const parameters = ptokens.map((pt) => {
      const tokens = pt.split(":");
      return { name: tokens[0].trim(), value: parseInt(tokens[1]) };
    });

    return { parameters, return: result };
  });

  return parsed;
}
