import { createTheme, useMediaQuery, useTheme } from "@mui/material";
import { useEffect } from "react";

export const theme = createTheme({});

const bp = theme.breakpoints.down("md");

// We just divide default theme by 2 for small screens.

theme.typography.h1 = {
  fontSize: "6rem",
  [bp]: {
    fontSize: "3rem",
  },
};

theme.typography.h2 = {
  fontSize: "3.75rem",
  [bp]: {
    fontSize: "1.88rem",
  },
};

theme.typography.h3 = {
  fontSize: "3rem",
  [bp]: {
    fontSize: "1.5rem",
  },
};

theme.typography.h4 = {
  fontSize: "2.125rem",
  [bp]: {
    fontSize: "1.067rem",
  },
};

theme.typography.h5 = {
  fontSize: "1.5rem",
  [bp]: {
    fontSize: "0.75rem",
  },
};

theme.typography.h6 = {
  fontSize: "1.25rem",
  [bp]: {
    fontSize: "0.625rem",
  },
};

export function useIsSmallScreen() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return matches;
}

export function CenterCoffee() {
  const isSmall = useIsSmallScreen();

  useEffect(() => {
    if (isSmall) {
      const coffee = document.getElementById("bmc-wbtn");

      if (coffee) {
        coffee.style.right = "calc(50vw - 32px)";
      }
    }
  }, [isSmall]);

  return <div />;
}
