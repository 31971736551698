import { Container, Divider, Link, Stack, Typography } from "@mui/material";

import { Link as RouterLink } from "react-router-dom";
import { Tutorial } from "./Tutorial";

export function Header(props: { hideByte?: boolean; hideHelp?: boolean }) {
  return (
    <Container maxWidth="md" sx={{ mt: 2 }}>
      <Stack direction="row" alignItems="baseline">
        <Typography variant="h3">Bitr1ck</Typography>

        <Stack
          direction="row"
          alignItems="baseline"
          spacing={1}
          sx={{ ml: "auto" }}
        >
          {props.hideByte ? (
            ""
          ) : (
            <Link component={RouterLink} to="/byte">
              All exercises
            </Link>
          )}

          {props.hideHelp ? "" : <Tutorial />}
        </Stack>
      </Stack>

      <Divider />
    </Container>
  );
}
