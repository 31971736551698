import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  Box,
  Container,
  Divider,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useLoaderData } from "react-router-dom";
import { useImmer } from "use-immer";
import { Footer, Header } from "../../components";
import { ADDR } from "../../CONFIG";
import { Exercise, ExerciseResult, InitialExercise } from "../../types";
import { Blocks, Function, RunItButton } from "./components";

function useMySensors() {
  const mSensor = useSensor(MouseSensor);
  const tSensor = useSensor(TouchSensor);

  const sensors = useSensors(mSensor, tSensor);

  return sensors;
}

function localizeBlocks(data: InitialExercise): Exercise {
  // Location can be either tray (for blocks in Blocks)
  // or id of Space in which block is.

  const blocks = data.blocks.map((x) => ({ ...x, location: "tray" }));

  return { ...data, blocks };
}

const initialResult: ExerciseResult = {
  hasSyntaxError: false,
  wasRun: false,
  passed: 0,
  unpassed: 0,
  isSuccess: false,
};

function TestResult(props: { result: ExerciseResult }) {
  const totalTestRuns = props.result.passed + props.result.unpassed;

  return (
    <Stack>
      {props.result.hasSyntaxError ? (
        <Typography variant="body2" color="error">
          Syntax error
        </Typography>
      ) : (
        <Typography
          variant="body2"
          color={props.result.unpassed > 0 ? "error" : undefined}
        >
          {props.result.passed} / {totalTestRuns} tests succesful.
        </Typography>
      )}
    </Stack>
  );
}

export function Bit() {
  const initialData = useLoaderData() as InitialExercise;

  const [data, setData] = useImmer(localizeBlocks(initialData));

  const [result, setResult] = useImmer(initialResult);

  useEffect(() => {
    if (initialData) {
      setData(localizeBlocks(initialData));
      setResult(initialResult);
    }
  }, [initialData]);

  const sensors = useMySensors();

  function onDragEnd(e: DragEndEvent) {
    const droppable = e.over?.id;
    const draggable = e.active.id;

    if (e.active.id === undefined) {
      return;
    }

    setData((d) => {
      const block = d.blocks.find((x) => x.id === draggable);

      if (block) {
        if (typeof droppable === "string") {
          block.location = droppable;
        } else {
          block.location = "tray";
        }
      }
    });
  }

  const isReadyToRun =
    data.blocks.filter((x) => x.location === "tray").length === 0;

  return (
    <DndContext sensors={sensors} onDragEnd={onDragEnd}>
      <Header />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 4,
        }}
      >
        <Container maxWidth="md">
          <Stack spacing={2}>
            <Function data={data} />

            <Divider />

            <Blocks blocks={data.blocks} />

            <Divider />

            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Stack>
                {data.source !== "" ? (
                  <Link href={data.source}>
                    <Typography variant="body2">Exercise source</Typography>
                  </Link>
                ) : (
                  ""
                )}

                <Link href="mailto:info@bitrick.si">
                  <Typography variant="body2">Report a problem</Typography>
                </Link>
              </Stack>

              {result.wasRun ? <TestResult result={result} /> : ""}

              <Box>
                <RunItButton
                  disabled={!isReadyToRun}
                  data={data}
                  setResult={setResult}
                />
              </Box>
            </Stack>
          </Stack>
        </Container>

        <Footer />
      </Box>
    </DndContext>
  );
}

export async function bitLoader(request: { params: { id: number } }) {
  const response = await fetch(`${ADDR}/bit/${request.params.id}.json`);

  const data: InitialExercise = await response.json();

  return data;
}
