import {
  Box,
  Container,
  Divider,
  Link,
  Stack,
  Typography,
} from "@mui/material";

export function Footer() {
  return (
    <Box
      sx={{
        width: "100vw",
        overflowX: "hidden",
        backgroundColor: "whitesmoke",
        py: 1,
        mt: 4,
      }}
    >
      <Divider />
      <Container maxWidth="md" sx={{ mt: 1 }}>
        <Stack spacing={1}>
          <Typography variant="body2">Bitr1ck by Matija Sirk</Typography>
          <Typography variant="body2">
            <Link href="https://gitlab.com/sirkmatija/bitr1ck">
              Source code
            </Link>
          </Typography>
        </Stack>
      </Container>
    </Box>
  );
}
