import { Grid, Stack } from "@mui/material";
import { ReactNode } from "react";

export function Column(props: { children?: ReactNode }) {
  return (
    <Grid item lg={4}>
      <Stack spacing={1}>{props.children}</Stack>
    </Grid>
  );
}
