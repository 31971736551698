import { useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { Button } from "@mui/material";
import { useIsSmallScreen } from "../../../theme";
import { Exercise } from "../../../types";

const operators = [
  ">>>",
  "-",
  "+",
  ">",
  "<",
  "^",
  "|",
  "&",
  ">>",
  "/",
  "~",
  "<<",
];

export function Block(props: { block: Exercise["blocks"][number] }) {
  const isSmallScreen = useIsSmallScreen();

  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: props.block.id,
  });

  const btnStyle = {
    transform: CSS.Translate.toString(transform),
    textTransform: "none" as const,
  };

  const spanStyle = {
    margin: 0,
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 400,
    fontSize: isSmallScreen ? "0.75rem" : "1.5rem",
    lineHeight: 1.334,
    letterSpacing: "0em",
  };

  let color: Parameters<typeof Button>[0]["color"] = "inherit";

  if (props.block.block.match("^[a-zA-Z]*$") !== null) {
    color = "primary";
  }

  if (operators.includes(props.block.block)) {
    color = "success";
  }

  if (props.block.block.includes("=")) {
    color = "secondary";
  }

  if (props.block.block.match("^[0-9]*$") !== null) {
    color = "warning";
  }

  return (
    <Button
      className="block"
      variant="contained"
      key={`block--${props.block.id}`}
      color={color}
      sx={btnStyle}
      {...listeners}
      {...attributes}
      ref={setNodeRef}
    >
      <span style={spanStyle}>{props.block.block}</span>
    </Button>
  );
}
