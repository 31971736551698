import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Updater } from "use-immer";
import { Exercise, ExerciseResult } from "../../../types";
import { parse } from "./parse";

function runExercise(data: Exercise) {
  const fn = parse(data);

  const test_runs = data.tests.map((test) => {
    let res = 0;
    let hasError = false;

    try {
      const params = test.parameters.map((x) => x.value);
      res = fn(...params);
    } catch (error) {
      hasError = true;
    }

    return { ...test, passed: res === test.return, result: res, hasError };
  });

  return test_runs;
}

export function RunItButton(props: {
  disabled: boolean;
  data: Exercise;
  setResult: Updater<ExerciseResult>;
}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        id="runIt"
        variant="contained"
        color="success"
        disabled={props.disabled}
        onClick={() => {
          const test_runs = runExercise(props.data);

          const hasSyntaxError =
            test_runs.map((x) => x.hasError).findIndex((x) => x) !== -1;

          const passed = test_runs.filter((x) => x.passed).length;
          const unpassed = test_runs.filter((x) => !x.passed).length;

          const isSuccess =
            !hasSyntaxError && passed === test_runs.length && unpassed === 0;

          console.log(test_runs);

          props.setResult((d) => {
            d.hasSyntaxError = hasSyntaxError;
            d.passed = passed;
            d.unpassed = unpassed;
            d.wasRun = true;
            d.isSuccess = isSuccess;
          });

          if (isSuccess) {
            localStorage.setItem(`bit/${props.data.id}--solved`, "true");
          }

          setIsOpen(isSuccess);
        }}
      >
        Run it!
      </Button>
      <Dialog
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <DialogContent>
          <Stack alignItems="center">
            <Typography variant="h1" component="p">
              🎉
            </Typography>

            <Typography variant="h3" component="p">
              All tests passed!
            </Typography>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Link
            component={RouterLink}
            to={`/bit/${props.data.id + 1}`}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <Button variant="contained" color="success">
              Next exercise
            </Button>
          </Link>
        </DialogActions>
      </Dialog>
    </>
  );
}
