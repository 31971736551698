import { useDroppable } from "@dnd-kit/core";
import { Box, Stack, Typography } from "@mui/material";
import { useIsSmallScreen } from "../../../theme";
import { Exercise } from "../../../types";
import { Block } from "./Block";

function Parameters(props: { parameters: Exercise["parameters"] }) {
  const num = props.parameters.length;

  const paramStyle = { color: "dodgerBlue" };
  const commaStyle = { color: "lightgray" };

  return (
    <>
      {props.parameters.map((x, i) => (
        <span key={`parameter--${i}`}>
          <span style={paramStyle}>{x}</span>
          <span style={commaStyle}>{": int32_t"}</span>
          {i + 1 !== num ? <span style={commaStyle}>{", "}</span> : ""}
        </span>
      ))}
    </>
  );
}

function Declaration(props: {
  fn: Exercise["fn"];
  parameters: Exercise["parameters"];
  returnType: Exercise["returnType"];
}) {
  const parenStyle = { color: "gray" };
  const typeStyle = { color: "lightgray" };

  const returnType = props.returnType ? props.returnType : "int32_t";

  return (
    <Typography variant="h4" component="span">
      <span style={typeStyle}>{returnType} </span>
      {props.fn}
      <span style={parenStyle}>{"("}</span>
      <Parameters parameters={props.parameters} />
      <span style={parenStyle}>{")"}</span> {"{"}
    </Typography>
  );
}

function Droppable(props: { id: string; block?: Exercise["blocks"][number] }) {
  const { setNodeRef } = useDroppable({ id: props.id });

  const isSmallScreen = useIsSmallScreen();

  const droppableStyle = {
    border: "1px solid lightgray",
    height: isSmallScreen ? "28px" : "44px",
    width: isSmallScreen ? "4em" : "5em",
    borderRadius: 1,
  };

  if (props.block) {
    return <Block block={props.block} />;
  }

  return <Box sx={droppableStyle} ref={setNodeRef} className="space" />;
}

function Comment(props: { comment: Exercise["comment"] }) {
  const commentStyle = { color: "mediumseagreen" };

  const lines = props.comment.split("\n");

  const commentedLines = lines.map((l) => `// ${l}`);

  return (
    <Stack spacing={1}>
      {commentedLines.map((line, i) => (
        <Typography
          sx={commentStyle}
          variant="h5"
          component="span"
          key={`comment-line--${i}`}
        >
          {line}
        </Typography>
      ))}
    </Stack>
  );
}

function Line(props: {
  data: Exercise["spaces"][number];
  comment: Exercise["comment"];
  blocks: Exercise["blocks"];
}) {
  const isSmallScreen = useIsSmallScreen();

  const returnStyle = { color: "dodgerblue" };

  const semiStyle = { color: "gray" };

  const hasNoNeedForSemi =
    props.data.length === 0 ||
    (props.data.length === 1 && props.data[0] === "comment");

  return (
    <Stack direction="row" spacing={1} alignItems="flex-end">
      {
        /* Handle empty line. */
        props.data.length === 0 ? (
          <Box sx={{ height: isSmallScreen ? "1em" : "2em" }} />
        ) : (
          props.data.map((el, i) => {
            if (el === "return") {
              return (
                <Typography
                  variant="h4"
                  component="span"
                  sx={returnStyle}
                  key="return"
                >
                  return
                </Typography>
              );
            } else if (el === "comment") {
              return <Comment comment={props.comment} key="comment" />;
            } else {
              /* Render droppable space. */
              return (
                <Droppable
                  key={el}
                  id={el}
                  block={props.blocks.find((x) => x.location === el)}
                />
              );
            }
          })
        )
      }

      {hasNoNeedForSemi ? (
        ""
      ) : (
        <Typography variant="h5" component="span" style={semiStyle}>
          ;
        </Typography>
      )}
    </Stack>
  );
}

function Body(props: {
  spaces: Exercise["spaces"];
  comment: Exercise["comment"];
  blocks: Exercise["blocks"];
}) {
  return (
    <Box sx={{ pl: 8 }}>
      <Stack spacing={1}>
        {props.spaces.map((row, i) => (
          <Line
            key={`line--${i}`}
            data={row}
            comment={props.comment}
            blocks={props.blocks}
          />
        ))}
      </Stack>
    </Box>
  );
}

function Tail() {
  return (
    <Typography variant="h4" component="span">
      {"}"}
    </Typography>
  );
}

export function Function(props: { data: Exercise }) {
  return (
    <Stack spacing={1}>
      <Declaration
        fn={props.data.fn}
        parameters={props.data.parameters}
        returnType={props.data.returnType}
      />
      <Body
        spaces={props.data.spaces}
        comment={props.data.comment}
        blocks={props.data.blocks}
      />
      <Tail />
    </Stack>
  );
}
