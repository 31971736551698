import { Button, Stack, TextField } from "@mui/material";
import { useEffect } from "react";
import { useImmer } from "use-immer";

export function SubmittableField(props: {
  onSubmit: (val: string) => void;
  value: string;
  multiline?: boolean;
  rows?: number;
  label?: string;
}) {
  const [state, setState] = useImmer(props.value);

  useEffect(() => {
    setState(props.value);
  }, [props.value]);

  return (
    <Stack direction="row" spacing={1}>
      <TextField
        sx={{ width: "100%" }}
        label={props.label}
        multiline={props.multiline}
        rows={props.rows}
        value={state}
        onChange={(e) => setState(e.target.value)}
        InputLabelProps={{ shrink: true }}
      />

      <Button variant="contained" onClick={() => props.onSubmit(state)}>
        Ok
      </Button>
    </Stack>
  );
}
