import { Avatar, Box, Container, Grid, Link } from "@mui/material";
import { Link as RouterLink, useLoaderData } from "react-router-dom";
import { Footer, Header } from "../components";
import { ADDR } from "../CONFIG";
import { ExerciseIndex } from "../types";

function BitLink(props: { data: ExerciseIndex }) {
  const size = 72;

  const isSolved =
    localStorage.getItem(`bit/${props.data.id}--solved`) === "true";

  return (
    <Link
      component={RouterLink}
      to={`/bit/${props.data.id}`}
      sx={{
        textDecoration: "none",
      }}
    >
      <Avatar
        sx={(theme) => ({
          width: size,
          height: size,
          bgcolor: isSolved
            ? theme.palette.success.main
            : theme.palette.primary.main,
        })}
      >
        {props.data.label}
      </Avatar>
    </Link>
  );
}

export function Byte() {
  const data = useLoaderData() as ExerciseIndex[];

  return (
    <>
      <Header hideByte hideHelp />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 4,
        }}
      >
        <Container maxWidth="sm">
          <Grid container spacing={1}>
            {data.map((x) => (
              <Grid
                item
                key={x.id}
                xs={3}
                sx={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <BitLink data={x} />
              </Grid>
            ))}
          </Grid>
        </Container>

        <Footer />
      </Box>
    </>
  );
}

export async function byteLoader() {
  const response = await fetch(`${ADDR}/byte.json`);

  const data: ExerciseIndex[] = await response.json();

  return data;
}
