import { Button, Link, Typography } from "@mui/material";
import { useState } from "react";
import Joyride, { Step } from "react-joyride";

const steps: Step[] = [
  {
    target: "#tray",
    content: (
      <Typography>
        Tray holds available blocks. Choose one and drag it with your mouse (or
        finger) upwards to available space.
      </Typography>
    ),
    disableBeacon: true,
  },
  {
    target: ".block",
    content: (
      <Typography>
        Blocks are fragments of code which you can compose into functions.
        Common ones are & (and), | (or), ^ (xor). If you need more information
        consult{" "}
        <Link href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators#expressions_and_operators_by_category">
          MDN
        </Link>
      </Typography>
    ),
    disableBeacon: true,
  },
  {
    target: ".space",
    content: <Typography>Spaces hold blocks.</Typography>,
    disableBeacon: true,
  },
  {
    target: "#runIt",
    content: (
      <Typography>
        Once you assign all blocks to spaces click this button to run your
        function. If all tests pass, you successfuly solved this exercise!
      </Typography>
    ),
    disableBeacon: true,
  },
];

export function Tutorial() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button variant="text" onClick={() => setIsOpen((x) => !x)}>
        Help
      </Button>

      <Joyride
        steps={steps}
        run={isOpen}
        continuous
        disableScrolling
        showSkipButton
        callback={(data) => {
          if (["close", "skip"].includes(data.action)) {
            setIsOpen(false);
          }
        }}
      />
    </>
  );
}
