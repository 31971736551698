import { Container, Grid, TextField, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useImmer } from "use-immer";
import { InitialExercise } from "../../types";
import { toString } from "./common";
import {
  Calculator,
  Column,
  Importer,
  StatusCheck,
  SubmittableField,
} from "./components";
import { parseBlocks, parseSpaces, parseTests } from "./parsers";

const initialExercise: InitialExercise = {
  id: 0,
  blocks: [],
  spaces: [],
  fn: "fn",
  comment: "",
  parameters: [],
  source: "",
  tests: [],
  returnType: "",
};

function deriveByte(ex: InitialExercise) {
  return toString({ id: ex.id, label: ex.fn.toLocaleUpperCase() });
}

function deriveBit(ex: InitialExercise) {
  return toString(ex);
}

export function ExerciseEditor() {
  const [state, setState] = useImmer(initialExercise);

  const byte = deriveByte(state);
  const bit = deriveBit(state);

  function onImport(val: string) {
    const payload = JSON.parse(val);

    setState(payload);
  }

  return (
    <Container maxWidth="xl">
      <Typography variant="h5" component="h1">
        <Link to="/">Home</Link>
      </Typography>

      <Grid container spacing={1}>
        <Importer onImport={onImport} />

        <Column>
          <Typography variant="h6" component="h2">
            Edit
          </Typography>

          <TextField
            label="id"
            value={state.id}
            onChange={(e) =>
              setState((d) => {
                d.id = parseInt(e.target.value);
              })
            }
            InputLabelProps={{ shrink: true }}
          />

          <TextField
            label="fn"
            value={state.fn}
            onChange={(e) =>
              setState((d) => {
                d.fn = e.target.value;
              })
            }
            InputLabelProps={{ shrink: true }}
          />

          <TextField
            label="return type"
            value={state.returnType}
            onChange={(e) =>
              setState((d) => {
                if (e.target.value.trim() !== "") {
                  d.returnType = e.target.value;
                } else {
                  d.returnType = undefined;
                }
              })
            }
            InputLabelProps={{ shrink: true }}
          />

          <TextField
            label="comment"
            multiline
            rows={3}
            value={state.comment}
            onChange={(e) =>
              setState((d) => {
                d.comment = e.target.value;
              })
            }
            InputLabelProps={{ shrink: true }}
          />

          <SubmittableField
            label="Parameters"
            value={toString(state.parameters)}
            onSubmit={(val: string) => {
              try {
                const params = JSON.parse(val);
                setState((d) => {
                  d.parameters = params;
                });
              } catch (error) {}
            }}
          />

          <TextField
            label="source"
            value={state.source}
            onChange={(e) =>
              setState((d) => {
                d.source = e.target.value;
              })
            }
            InputLabelProps={{ shrink: true }}
          />

          <SubmittableField
            label="spaces"
            multiline
            rows={5}
            value={state.spaces
              .map((row) =>
                row.includes("comment")
                  ? `comment ${row.length - 1}` // -1 for "comment" place in array.
                  : row.includes("return")
                  ? `return ${row.length - 1}` // -1 for "return" place in array.
                  : `${row.length}`
              )
              .join("\n")}
            onSubmit={(val: string) => {
              const spaces = parseSpaces(val);

              setState((d) => {
                d.spaces = spaces;
              });
            }}
          />

          <SubmittableField
            label="blocks"
            multiline
            rows={5}
            value={state.blocks.map((x) => x.block).join("\n")}
            onSubmit={(val: string) => {
              const blocks = parseBlocks(val);

              setState((d) => {
                d.blocks = blocks;
              });
            }}
          />

          <SubmittableField
            label="tests"
            multiline
            rows={5}
            value={state.tests
              .map((test) => {
                return (
                  test.parameters
                    .map((x) => `${x.name}: ${x.value}`)
                    .join(", ") +
                  " => " +
                  test.return
                );
              })
              .join("\n")}
            onSubmit={(val: string) => {
              const tests = parseTests(val);

              setState((d) => {
                d.tests = tests;
              });
            }}
          />
        </Column>

        <Column>
          <Typography variant="h6" component="h2">
            Export
          </Typography>

          <TextField
            multiline
            rows={4}
            label="byte"
            value={byte}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            multiline
            rows={16}
            label="bit"
            value={bit}
            InputLabelProps={{ shrink: true }}
          />

          <Typography>
            <a href="https://jsonformatter.curiousconcept.com/">Prettify</a>
          </Typography>

          <StatusCheck data={state} />

          <Calculator />
        </Column>
      </Grid>
    </Container>
  );
}
