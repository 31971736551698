import { Stack, TextField } from "@mui/material";
import { useImmer } from "use-immer";

export function Calculator() {
  const [state, setState] = useImmer("");

  const bToD = parseInt(state, 2);
  const dToB = (parseInt(state) >> 0).toString(2);

  const isInputProbablyBinary = state.match("^[01]*$") !== null;

  return (
    <Stack spacing={1} sx={{ p: 1, border: "1px solid gray", borderRadius: 1 }}>
      <TextField
        label="input"
        InputLabelProps={{ shrink: true }}
        value={state}
        onChange={(e) => setState(e.target.value)}
      />
      <TextField
        label="binary->decimal"
        InputLabelProps={{ shrink: true }}
        value={bToD}
        error={!isInputProbablyBinary}
        variant="filled"
      />
      <TextField
        label="decimal->binary"
        InputLabelProps={{ shrink: true }}
        value={dToB}
        error={isInputProbablyBinary}
        variant="filled"
      />
    </Stack>
  );
}
