import { Box, Stack } from "@mui/material";
import { Exercise } from "../../../types";
import { Block } from "./Block";

export function Blocks(props: { blocks: Exercise["blocks"] }) {
  const inTray = props.blocks.filter((x) => x.location === "tray");

  return (
    <Box
      sx={{
        p: 1,
        border: "1px solid lightgray",
        borderRadius: 1,
        minHeight: 62,
      }}
      id="tray"
    >
      <Stack direction="row" spacing={1} flexWrap="wrap">
        {inTray.map((block) => (
          <Block block={block} key={block.id} />
        ))}
      </Stack>
    </Box>
  );
}
